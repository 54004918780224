import { createApp } from 'vue';
import App from './App.vue';

import router from "./router";
import ElementPlus from "element-plus";
import * as ElementPlusIcon from "@element-plus/icons-vue";

import 'element-plus/dist/index.css';
// import i18n from './assets/language/index.js';
import {createI18n} from 'vue-i18n';
// import vuescroll from 'vuescroll'
// createApp.use(router);
// createApp.use(ElementPlus);
import zh from './assets/language/zh.js'
import en from './assets/language/en.js'
import './assets/css/style.css'
import './registerServiceWorker.js'
//import VueGtag from "vue-gtag";
//import gaPulgin from './util/gaPulgin.js'
// 1. 引入你需要的组件
import { Popover, Rate, Tab,Tabs,PullRefresh, Image as VanImage ,Col, Row,Toast,Lazyload ,Loading ,Search,Button,Cell,CellGroup,Sticky,Empty,Swipe,Icon,Grid, GridItem,List,TextEllipsis,NavBar } from 'vant';

// 2. 引入组件样式
import 'vant/lib/index.css';

import './registerServiceWorker'


const messages = {
    en,
    zh
  };

const i18n = new createI18n({
  locale: 'zh', // 设置地区
  messages, // 设置地区信息
})
const app = createApp(App);
// 全局导入plus图标
for (let iconName in ElementPlusIcon) {
	app.component(iconName, ElementPlusIcon[iconName])
}
// router.afterEach((to, from) => {
//   ga('set', 'page', to.path)
//   ga('send', 'pageview')
// });
app.config.productionTip = false;
app.config.devtools = true;
app.use(router);
// app.use(ElementPlus,{ locale });
app.use(ElementPlus);
app.use(Popover);
// app.use(ElementPlusIcon);
app.use(i18n);
app.use(Rate);
app.use(Tab);
app.use(Tabs);
app.use(VanImage);
app.use(PullRefresh);
app.use(Col);
app.use(Row);
app.use(Toast);
app.use(Lazyload);
app.use(Loading);
app.use(Search);
app.use(Button);
app.use(Cell);
app.use(CellGroup);
app.use(Sticky);
app.use(Empty);
app.use(Swipe);
app.use(Icon);
app.use(Grid);
app.use(GridItem);
app.use(List);
app.use(TextEllipsis);
app.use(NavBar);
//app.use(gaPulgin)
// app.use(vuescroll);
// app.use(VueGtag, {
//   config: {
//     id: "G-KVSXWXSHV6",
//     params: {
//       IP: '127.0.0.1'
//     }
//    }
// });
app.mount('#app');
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  window.deferredPrompt = e;
})