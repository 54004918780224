<template>
  <router-view></router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}

html {
  /* background-color: #F8F8F8; */
}

body {
  margin: 0;
}

::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1 !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #748AA1 !important;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #748AA1 !important;
}
</style>
